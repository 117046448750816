.page-banner {
  padding-top: 68px;
  padding-bottom: 40px;
  // background-image: url("https://static.vecteezy.com/system/resources/previews/025/269/246/large_2x/global-network-connection-world-map-point-and-line-composition-concept-of-global-business-illustration-vector.jpg");
  background-image: url("../../assets/images/banner.png");
  background-position: right center;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 350px;
  display: flex;
  align-items: center;

  .banner-title {
    font-size: max(40px, 4vw);
    line-height: normal;
    margin-bottom: 0px;
    span {
      color: $color-text;
    }
  }

  @media (max-width: 768px) {
    height: 200px;
    background-size: 100%;
    background-position: right -200px center;
  }
}
.page-content {
  padding: 30px 0;
}

.greeting-text {
  margin: 60px 0;
  @media (max-width: 768px) {
    margin: 20px 0;
  }
}

.card {
  border: 2px solid $color-border;
  border-radius: 10px;
  height: 100%;
  background-color: $color-background;
  color: $color-text;
}
.vision-mission {
  .card {
    background-image: linear-gradient(45deg, #0f1724, $color-border);
    .card-body {
      display: flex;
      gap: 24px;
      .vision,
      .mission {
        display: flex;
        flex-direction: column;
        align-content: space-between;
      }
      .our-values {
        position: relative;
        z-index: 0;
        ul {
          padding: 0;
          margin-top: 24px;
          li {
            list-style: none;
            margin-bottom: 16px;
            p,
            h6 {
              // display: inline;
              margin-right: 8px;
            }
          }
        }
      }

      &:has(.our-values) {
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          display: block;
          opacity: 0.3;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0%;
          right: 0%;
          z-index: 0;
          background-image: url("../../assets/images/banner.png");
          background-position: right -50% top;
          background-size: 60%;
          background-repeat: no-repeat;
        }
      }
      .service-title {
        text-align: right;
      }
      .description {
        font-size: 18px;
        letter-spacing: 1px;
        margin: auto;
      }
    }
  }
  .img-wrapper {
    width: 200px;
    min-width: 200px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    svg,
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: 768px) {
    .card .card-body {
      flex-direction: column;
      gap: 16px;
      .service-title {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .img-wrapper {
        height: 100px;
        width: 100px;
        min-width: 100px;
      }
    }
  }
}

.our-commitment {
  max-width: 700px;
  margin: 100px auto !important;
  font-size: 18px;
  letter-spacing: 1px;
  margin: auto;
}

.our-story {
  padding: 80px 0;
  .story-tree-wrapper {
    .list-stories {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 80px;
      &::before {
        content: "";
        height: 100%;
        position: absolute;
        width: 2px;
        display: block;
        background-color: $color-border;
        background-image: linear-gradient(
          to bottom,
          $color-border 90%,
          transparent
        );
        background-color: transparent;
        left: 50%;
      }
      .story-item {
        display: flex;
        align-items: center;
        gap: 24px;
        width: max(400px, 50%);
        max-width: 100%;
        margin-left: auto;
        transform: translateX(-65px);
        .thumbnail {
          height: 130px;
          width: 130px;
          min-width: 130px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid $color-border;
          padding: 5px;
          background-color: $color-background;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .story-content {
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          text-align: right;
          margin-right: auto;
          margin-left: 0;
          transform: translateX(65px);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .story-tree-wrapper .list-stories {
      &::before {
        left: 65px;
      }
      .story-item {
        transform: translateX(0);
        align-items: flex-start;
        width: 100%;
        &:nth-child(even) {
          transform: translateX(0);
          flex-direction: row;
          text-align: left;
        }
      }
    }
  }
}
