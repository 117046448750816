.section {
  &.h-100 {
    min-height: 100svh;
  }
  position: relative;
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
}

.banner-section {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .stars-canvas-animation,
  .planets-canvas-animation,
  .earth-canvas-animation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .stars-canvas-animation {
    // position: fixed;
    z-index: 0;
    pointer-events: none;
  }
  .tag-line-container {
    position: relative;
    text-align: center;
    margin: 0 auto;

    .tag-line {
      letter-spacing: 1px;
      font-size: max(3vw, 22px);
      font-family: $fontPrimaryLight;
      line-height: 1.4;
      color: $color-text;
    }
  }

  @media (max-width: 768px) {
    .stars-canvas-animation,
    .planets-canvas-animation,
    .earth-canvas-animation {
      pointer-events: none;
    }
  }
}

.services-section {
  .services-container {
    .service-card {
      border: 2px solid $color-border;
      border-radius: 10px;
      height: 100%;
      background-color: $color-background;
      overflow: hidden;
      .card-body {
        position: relative;
        z-index: 0;
        color: $color-text;
        padding: 0;
        .service-title {
          padding: 24px;
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: transform 0s linear 0.1s;
          color: $color-text;
          .service-icon {
            .rb-service-icon {
              height: 130px;
              width: 130px;
              object-fit: contain;
              object-p8ition: center;
              border: 1px solid $color-border;
              padding: 6px;
              background-image: linear-gradient(45deg, #0f1724, $color-border);
              border-radius: 10px;
              fill: url(#service-icon-gradient) $color-text;
              stroke: url(#service-icon-gradient) $color-text;
            }
            margin-bottom: 100px;
            flex: 1;
          }
        }
        .service-description {
          position: absolute;
          padding: 16px 24px;
          left: 100%;
          top: 0;
          width: 100%;
          height: 100%;
          transition: transform 0s linear 0.1s;
          p {
            line-height: 1.6;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-family: $fontBodyLight;
            margin-bottom: 0;
          }

          .view-more-link {
            position: absolute;
            bottom: 16px;
            right: 24px;
            a {
              color: $color-text;

              i.bi {
                transition: 0.3s transform ease;
              }

              &:hover {
                i.bi {
                  transform: translateX(6px);
                }
              }
            }
          }
        }
      }

      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s,
        background-image 0.3s linear 0.3s;
    }
    .service-card-wrapper {
      &:hover {
        .service-card {
          transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s,
            background-image 0.3s linear 0.3s;
          transform: perspective(500px) rotateY(180deg);
          background-image: linear-gradient(45deg, #0f1724, $color-border);

          .service-title {
            transform: translateX(-100%);
          }
          .service-description {
            transform: translateX(-100%) rotateY(180deg);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .services-container {
      .service-card .card-body .service-title {
        padding: 12px;

        .service-icon {
          margin-bottom: 20px;
          .rb-service-icon {
            height: 70px;
            width: 70px;
          }
        }
        h4 {
          font-size: 16px;
          line-height: normal;
        }
      }
      .service-card-wrapper {
        .service-card {
          .service-description {
            position: fixed;
            top: 0;
            left: 0;
            height: auto;
            width: 100%;
            transform: none;
            opacity: 0;
            visibility: hidden;
          }
        }
        &:hover {
          .service-card {
            transform: perspective(0) rotateY(0deg);
            .service-title {
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
}

.tech-stack-section {
  .tech-stacks {
    .tech-stack-thumb {
      position: relative;
      margin-top: 80px;
      .animation-logo {
        position: absolute;
        top: -5%;
        left: 50%;
        transform: translateX(-50%);
        height: min(80px, 10vh);
        max-height: 60%;
        width: auto;
      }
    }
    .tech-stack-description p {
      line-height: 1.8;
      // font-size: 14px;
      letter-spacing: 0.5px;
      font-family: $fontBodyLight;
      margin-bottom: 0;
    }

    .tech-perspective {
      .path-wrapper {
        position: relative;
        .animation-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .circle-paths {
          max-width: 100%;
          height: 100%;
        }
      }
      .animateTechs {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        animation: TechCircleAnimation 100s linear infinite;
        .tech-svgs {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-around;
          .svg {
            position: absolute;
            top: 10%;
            left: 10%;
            // transform: translate(-50%, -50%);
            width: 8%;
            height: 8%;
            object-fit: contain;
            margin: 0 40px;
            animation: TechCircleAnimation 100s linear infinite reverse;

            &:nth-child(1) {
              top: 24%;
              left: -5%;
            }
            &:nth-child(2) {
              top: 18%;
              left: 18%;
            }
            &:nth-child(3) {
              top: 86%;
              left: 71%;
            }
            &:nth-child(4) {
              top: 51%;
              left: 64%;
            }
            &:nth-child(5) {
              top: 71%;
              left: 43%;
            }
            &:nth-child(6) {
              top: 94%;
              left: 27%;
            }
            &:nth-child(7) {
              top: 68%;
              left: -6%;
            }
            &:nth-child(8) {
              top: -3%;
              left: 40%;
            }
            &:nth-child(9) {
              top: 57%;
              left: 19%;
            }
            &:nth-child(10) {
              top: 30%;
              left: 89%;
            }
            &:nth-child(11) {
              top: 75%;
              left: 64%;
            }
            &:nth-child(12) {
              top: 27%;
              left: 55%;
            }
            &:nth-child(13) {
              top: 76%;
              left: 20%;
            }
            &:nth-child(14) {
              top: 28%;
              left: 73%;
            }
            &:nth-child(15) {
              top: 10%;
              left: 51%;
            }
            &:nth-child(16) {
              top: 43%;
              left: 4%;
            }
            &:nth-child(17) {
              top: 10%;
              left: 76%;
            }
            &:nth-child(18) {
              top: 55%;
              left: 91%;
            }
            &:nth-child(19) {
              top: 28%;
              left: 24%;
            }
          }
        }
      }
    }
  }
}

.statistics-section {
  // [class*="col-"]:not(:last-child) {
  .statistics {
    position: relative;
    // background-color: lighten($color-background, 2%);
    background-image: linear-gradient(-45deg, #0f1724, $color-border);
    padding: 16px 24px;
    border: 2px solid $color-border;
    border-radius: 10px;
    text-align: center;
    &::before {
      content: "";
      height: 2px;
      width: 100px;
      background-color: $color-text;
      display: block;
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0px 6px 0px rgba($color-text, 0.8),
        0px 0px 6px 0px rgba($color-text, 0.3);
      transition: 0.1s ease;
    }

    &:hover {
      &::before {
        box-shadow: 0px 0px 6px 0px rgba($color-text, 0.8),
          0px 10px 26px 4px rgba($color-text, 0.3);
      }
    }
  }
  // }
}

.clients-section {
  .clients-wrapper {
    .client-brand-img {
      background-image: linear-gradient(
        45deg,
        darken($color-border, 13%),
        lighten($color-background, 5%),
        darken($color-border, 13%)
      );
      padding-top: 30px;
      padding-bottom: 30px;
      .slick-track {
        align-items: center;
        .slick-slide {
          padding: 0 20px;
          outline: 0;
          .client-logo {
            height: 60px;
            width: 100%;
            object-fit: contain;
            object-position: center;
            transition: 0.4s;
            display: block;
            margin: 0 auto;
            filter: grayscale(1);
            &:focus,
            &:focus-visible {
              outline: 0;
            }
            &:hover {
              filter: grayscale(0);
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .clients-wrapper {
      .client-brand-img {
        padding-top: 15px;
        padding-bottom: 15px;
        .slick-track .slick-slide .client-logo {
          height: 40px;
        }
      }
    }
  }
}

.locations {
  .map-container {
    position: relative;
    .pin-container {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      .map-pin {
        height: 4%;
        // width: 20px;
        position: absolute;

        &.bounce {
          animation: bounce 1.5s ease infinite;
        }

        &.ahmedabad {
          left: 65.9%;
          top: 57.3%;
        }
        &.bangalore {
          left: 67.5%;
          top: 61.5%;
        }
        &.canada {
          left: 23.7%;
          top: 46.5%;
        }
      }
    }
    .ag-canvas {
      .world-map {
        height: 50%;
        fill: $color-border;

        #CA,
        #IN {
          fill: lighten($color-border, 10%);
        }
      }
    }
  }
  .address-container {
    .address {
      padding: 12px;
      border: 2px solid $color-border;
      margin-bottom: 24px;
      border-radius: 8px;
      a {
        color: $color-text;
        &:hover {
          text-decoration: underline;
        }
      }
      .location-icon {
        padding: 4px;
        margin-right: 15px;
        .bi {
          font-size: 18px;
        }
      }

      &:hover {
        @extend .border-animation-box;
      }
    }
  }
}

/* Animatad Border */
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.border-animation-box {
  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    #070e1b,
    #070e1b 5%,
    #070e1b 60%,
    #070e1b 95%
  );
  border: solid 2px transparent;
  // border-radius: 2em;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #1b3246,
    #5265bd 99%,
    transparent
  );

  background: 
    // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box,
    // border-box extends this background to the border space
    var(--gradient-border) border-box,
    // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-16px) scale(1.5);
  }
  60% {
    transform: translateY(-8px);
  }
}

@keyframes moveUp {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translate3d(0px, 10px, 0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
}

.moveUp {
  animation: moveUp 2s infinite;
}

@keyframes moveDown {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translate3d(0px, -10px, 0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
}

.moveDown {
  animation: moveDown 2s infinite;
}

.wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

ul#scene {
  list-style-type: none;
}

.layer {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
}

li.layer a {
  color: white;
  text-decoration: none;
  text-shadow: 3px 3px 3px #ccc;
  display: block;
}
li.layer:nth-child(1) {
} /*1 item*/

/*-----Logotype----------------*/
li.layer:nth-child(2) a {
  font-size: 3.5vw;
  color: white;
  text-shadow: 2px 2px 2px #ccc;
  padding: 7px;
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #ccc;
  position: absolute;
  top: 40vh;
  left: 40vw;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

li.layer:nth-child(2) a:hover {
  font-size: 4.5vw;
}

/*-----P----------------------*/
li.layer:nth-child(3) a {
  font-size: 5vw;
  position: absolute;
  top: 15vh;
  left: 15vw;
}

/*-----O---------------------*/
li.layer:nth-child(4) a {
  font-size: 8vw;
  position: absolute;
  top: 40vh;
  left: 22vw;
}

/*-----R---------------------*/
li.layer:nth-child(5) a {
  font-size: 6vw;
  position: absolute;
  top: 70vh;
  left: 35vw;
}

/*-----T---------------------*/
li.layer:nth-child(6) a {
  font-size: 3.5vw;
  position: absolute;
  top: 60vh;
  left: 47vw;
}

/*-----F---------------------*/
li.layer:nth-child(7) a {
  font-size: 3.5vw;
  position: absolute;
  top: 18vh;
  left: 40vw;
}

/*-----O---------------------*/
li.layer:nth-child(8) a {
  font-size: 3.5vw;
  position: absolute;
  top: 18vh;
  left: 55vw;
}

/*-----L---------------------*/
li.layer:nth-child(9) a {
  font-size: 5vw;
  position: absolute;
  top: 15vh;
  right: 20vw;
}

/*-----I---------------------*/
li.layer:nth-child(10) a {
  font-size: 6vw;
  position: absolute;
  top: 35vh;
  right: 30vw;
}
/*-----O---------------------*/
li.layer:nth-child(11) a {
  font-size: 6.5vw;
  position: absolute;
  top: 65vh;
  right: 37vw;
}

@keyframes TechCircleAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
