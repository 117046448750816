.main-footer {
  padding: 80px 0 0;
  position: relative;
  background-image: linear-gradient(180deg, transparent, #040911);
  .brand-logo-container {
    margin-bottom: 30px;
  }
  .privacy-statment {
    border-top: 1px solid $color-border;
  }
  ul {
    padding: 0;
    margin: 15px 0;
    li {
      list-style: none;
      margin-bottom: 12px;
    }
  }
  h6,
  a,
  p {
    color: $color-light;
    line-height: 1.8;
  }

  .social-media-wrapper {
  }
}

@media (max-width: 768px) {
  .main-footer {
    padding: 20px 0 0;
  }
  .privacy-statment {
    font-size: 12px;
    text-align: center;
  }
}
