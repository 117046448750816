/**
-------------------------------------------------------------
-------------------------CSS GLOBAL--------------------------
-------------------------------------------------------------
**/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  font-family: $fontBodyLight;
  background-color: $color-background;
  color: $color-text;
  margin-right: calc((100vw - 100%) * -1);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include customScrollbar();

  *::selection {
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  // color: $color-primary;

  &.link {
    text-decoration: underline;
  }
}

a,
button,
input,
select,
textarea,
div {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

.small,
small {
  font-size: 12px !important;
  font-family: $fontBodyRegular;
}

/* GLOBLE FOR BOOTSTRAP ICONS */
.bi {
  line-height: 1;
}

hr {
  border: 0;
  border-top: 1px solid $color-border;
}

$headings: (
  "h1": 36,
  "h2": 32,
  "h3": 28,
  "h4": 24,
  "h5": 20,
  "h6": 16,
);

@each $heading, $size in $headings {
  #{$heading} {
    font-size: rem($size);
    line-height: rem($size + 6);
    font-family: $fontPrimaryBold;
    margin: 0;
    margin-bottom: rem(calc($size / 4));
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.035em;

    &.fw-reg,
    .fw-reg {
      font-family: $fontPrimaryRegular;
    }
    &.fw-med,
    .fw-med {
      font-family: $fontPrimaryMedium;
    }
    &.fw-sbo,
    .fw-sbo {
      font-family: $fontPrimarySemiBold;
    }
  }
}

.loading {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-background;
  z-index: 9999;
  transition: 0.2s ease-out;
  .loading-wrapper {
    position: relative;
    height: 160px;
    width: 160px;
    // background-color: green;
    .animation-logo {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  &.fade-out {
    transform: scale(2);
    opacity: 0;
  }
}

.animation-logo {
  .OuterCircle {
    transform-origin: center 55.89%;
    // animatiozn: loadingRMBricks 1s linear 0s 3;
  }
  .InnerCircle {
    transform-origin: center 55.89%;
    // animation: loadingRMBricks 1s linear 0s 3 reverse;
  }
  .LowerPoint {
    opacity: 0;
    animation: connectionAnimation 6s linear 0.1s infinite;
  }
  .MiddlePoint {
    opacity: 0;
    animation: connectionAnimation 6s linear 0.2s infinite;
  }
  .HigherPoint {
    opacity: 0;
    animation: connectionAnimation 6s linear 0.3s infinite;
    // animation: name duration timing-function delay iteration-count direction fill-mode;
  }
}

html:has(.app-loading) {
  overflow: hidden;
}
.remote-wrapper {
  transition: transform 0.3s ease-out 0s, opacity 1s ease-out 0s;
  transform-origin: top center;
  opacity: 1;
  &.bricks-loading {
    transform: scale(0.6);
    opacity: 0;
  }
}

@keyframes loadingRMBricks {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes connectionAnimation {
  100%,
  80%,
  60%,
  40%,
  20%,
  0% {
    opacity: 0;
  }
  90%,
  70%,
  50%,
  30%,
  10% {
    opacity: 1;
  }
}
